import React, { useEffect, useState } from 'react';
import './App.css';
import { motion } from 'framer-motion';

function App() {
  const [showUnderConstruction, setShowUnderConstruction] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowUnderConstruction(true);
    }, 3000); // 3 secondes avant de commencer l'animation de "Work In Progress"
  }, []);

  const mainTextVariants = {
    animate: {
      scale: [1, 1.8, 1],
      transition: {
        duration: 1.15,
        times: [0, 0.05, 1],
        ease: ['easeOut', 'linear'],
        repeat: Infinity,
        repeatType: "loop"
      }
    }
  };

  const letterAnimation = {
    initial: { opacity: 0 },
    animate: showUnderConstruction ? { opacity: 1 } : { opacity: 0 },
    transition: { duration: 0.1 }
  };

  const underConstructionText = "Work In Progress".split("").map((char, index) => (
    <motion.span
      key={index}
      variants={letterAnimation}
      initial="initial"
      animate="animate"
      style={{ display: 'inline-block', marginRight: char === ' ' ? '0.25em' : '0' }}
      transition={{ delay:  index * (3 / "Work In Progress".length) }} // Divisez la durée totale par le nombre de lettres
    >
      {char}
    </motion.span>
  ));

  return (
    <div className="app">
      {/* Texte Principal */}
      <motion.div
        className="animated-text"
        variants={mainTextVariants}
        initial="initial"
        animate="animate"
      >
        ChodaProd
      </motion.div>

      {/* Texte "Work In Progress" */}
      <div className="under-construction">
        {underConstructionText}
      </div>
    </div>
  );
}

export default App;
